import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { Exo_2, Onest } from 'next/font/google';
import { FaGithub, FaInstagram, FaLinkedin, FaXTwitter } from 'react-icons/fa6';

const exo2 = Exo_2({ weight: ['300', '400', '700'], subsets: ['latin'] });

const headFont = Onest({
  weight: ['400', '600'],
  subsets: ['latin'],
});
const Footer: React.FunctionComponent = () => {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

  return (
    <footer
      className={cn(
        'p-4 py-10 lg:py-20 w-full text-sm border-t border-primary bg-gradient-to-br from-background via-background to-primary',
        headFont.className
      )}
    >
      <div className="flex flex-col gap-4 md:flex-row items-start justify-between max-w-4xl mx-auto w-full">
        <div className="flex flex-col gap-2">
          <Link
            href="/"
            className="hover:opacity-80 text-2xl w-fit transition ease-in-out duration-200"
            aria-label="homepage"
          >
            <div className="flex items-center gap-2">
              <Image
                src={'/logo-enhanced.svg'}
                alt={'fashn-logo'}
                width={'30'}
                height={'302'}
              />
              <h2 className={cn(exo2.className, 'font-bold')}>
                FASHN<span className="font-light">AI</span>
              </h2>
            </div>
          </Link>
          <div className="flex flex-col gap-4">
            <p className="opacity-80">
              {`© ${new Date().getFullYear()} FASHN LTD. All rights reserved`}.
            </p>
            <p className="text-foreground/80 text-xs">
              71-75 Shelton Street, Covent Garden
              <br />
              London, UK, WC2H 9JQ
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <h3>Resources</h3>
          <ul className="flex flex-col gap-4">
            <ListItem>
              <Link href="/blog" aria-label="blog">
                Blog
              </Link>
            </ListItem>
            <ListItem>
              <a
                href="https://docs.fashn.ai"
                target={'_blank'}
                aria-label="docs"
              >
                Docs
              </a>
            </ListItem>
          </ul>
        </div>
        <div className="flex flex-col gap-6">
          <h3>Products</h3>
          <ul className="flex flex-col gap-4">
            <ListItem>
              <Link href={`/products/api`} aria-label="api">
                API
              </Link>
            </ListItem>
            <ListItem>
              <Link href={`/products/web-app`} aria-label="platform">
                Web App
              </Link>
            </ListItem>
          </ul>
        </div>
        <div className="flex flex-col gap-6">
          <h3>Legal</h3>
          <ul className="flex flex-col gap-4">
            <ListItem>
              <Link href={`/privacy-policy`} aria-label="privacy policy">
                Privacy Policy
              </Link>
            </ListItem>
            <ListItem>
              <Link href={`/terms-of-use`} aria-label="terms of use">
                Terms of Use
              </Link>
            </ListItem>
          </ul>
        </div>
        <div className="flex flex-col gap-6 ">
          <h3>Follow</h3>
          <div className="flex flex-col gap-4">
            <ul className="flex gap-2 items-center">
              <ListItem>
                <a
                  href="https://x.com/fashn_ai"
                  target="_blank"
                  aria-label="twitter"
                >
                  <FaXTwitter size={18} />
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="https://www.instagram.com/fashn.ai/"
                  target="_blank"
                  aria-label="instagram"
                >
                  <FaInstagram size={18} />
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="https://www.linkedin.com/company/fashn"
                  target="_blank"
                  aria-label="linkedin"
                >
                  <FaLinkedin size={18} />
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="https://github.com/fashn-AI"
                  target="_blank"
                  aria-label="github"
                >
                  <FaGithub size={18} />
                </a>
              </ListItem>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const ListItem = ({ children }: any) => {
  return (
    <li
      className={`hover:opacity-80 transition ease-in-out duration-200 text-foreground/80 w-fit`}
    >
      {children}
    </li>
  );
};
